export type Tariff={
    Name:string
    Description:string
    Price:string
    UsageDays:number
}

let t1:Tariff={
    UsageDays:30,
    Name:"БАЗОВЫЙ",
    Price:"490",
    Description:"• Уведомления о заказах/ выкупах/ возвратах с фото и стоимостью товара \n" +
        "• Проверка позиций по ключевым/поисковым запросам  30 позиций по одному городу на выбор.\n" +
        "• Анализ динамики позиций (рост/снижение позиций по ключевым запросам в поисковой выдаче 30 ключевых запросов)\n" +
        "• Возможность смены города для отслеживания позиций 3 раза в месяц\n" +
        "• Сохранение и выгрузка в exel данных по динамике позиций за последние 14 дней использования сервиса\n" +
        "• Общие остатки по складам \n" +
        "• Процент выкупа по товару/артикулу\n" +
        "• Тех. поддержка с 9-21 (по МСК без выходных) \n",

}
let t2:Tariff={
    UsageDays:30,
    Name:"РАСШИРЕННЫЙ ",
    Price:"1 990",
    Description:"• Уведомления о заказах/ выкупах/ возвратах с фото и стоимостью товара\n" +
        "• Проверка позиций по всем ключевым/поисковым запросам 40 ключевых запросов и 5 городов на выбор\n" +
        "• Анализ динамики позиций (рост/снижение позиций по всем ключевым запросам в поисковой выдаче по всем выбранным городам\n" +
        "• Возможность смены города для отслеживания позиций 5 раза в месяц\n" +
        "• Сохранение и выгрузка в exel данных по динамике позиций за все время использования сервиса за 14 дней по всем городам.\n" +
        "• Выгрузка в exel остатков по складам с фото размерами и индикаторами товаров которые заканчиваются. \n" +
        "• Уведомления о \"выпавшем\" из остатков товаре/размере\n" +
        "• Процент выкупа по товару/артикулу.\n" +
        "• Лимиты по складам  при запросе\n" +
        "• Уведомления о возможности бесплатной поставки на конкретный склад (связано с пунктом выше)\n" +
        "• ABC-XYZ анализ \n" +
        "• Финансовый отчёт с полной и понятной расшифровкой\n" +
        "(логистика, штрафы, хранение, налог, процент wb, прибыль).\n"+
        "• Тех. поддержка с 9-21 (по МСК без выходных)"
}


export let AllTariffs:Array<Tariff>=[t1,t2]