import React, {FC, useEffect, useState} from 'react';
//@ts-ignore
import cl from "./Main.module.css"

let text: string = "Функционал сервиса MP JUMP:\n" +
    "\n" +
    "- Контроль заказов в реальном времени\n" +
    "- Отслеживание роста позиции\n" +
    "- Контроль выкупов и возвратов ваших товаров\n" +
    "- Помощь и рекомендации по контролю остатков и размеров по складам\n" +
    "- Сервис покажет какие у вас работают ключи их частотность и так же покажет какие ключевые запросы подходящие под ваш товар у вас отсутствуют\n" +
    "- Удобная и понятная для всех информация разделена на excel таблицы в которых разберется абсолютно каждый"


let showCl: string = "contentHead"
let closeCl: string = "contentHeadClose"
const Main: FC = () => {
    const [classN, setClassN] = useState<string>(showCl)
    useEffect(() => {
        return () => {
            setClassN(() => closeCl)
        }
    },)
    return (
        <div>
            <div className={classN}>
                <h2>Функционал сервиса</h2>
                <img src={"img/logo_main.png"} alt={"img/logo_main.png"}/>
            </div>
            <div className={"routerPageContent"}>
                <div className={cl.wrapper}>
                    <p>{text}</p>
                    <a href={"oferta.pdf"} download={true}>Договор оферты</a>
                </div>
            </div>
        </div>
    );
};

export default Main;