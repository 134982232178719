import React, {FC, useEffect, useState} from 'react';

import './App.css';
import {BrowserRouter} from "react-router-dom";
import RoutesView from "./router/routesView/RoutesView";
import AppRouter from "./router/AppRouter";

const App:FC=()=>{

        return (
            <div className="App">

                <BrowserRouter>

                    <RoutesView/>
                    <AppRouter/>

                </BrowserRouter>

            </div>
        );



}

export default App;
