import React, {FC} from 'react';
// @ts-ignore
import cl from "./Contacts.module.css"

let text: string = "Индивидуальный предприниматель\n" +
    "\n" +
    "Тереков Сергей Иванович\n" +
    "OГPНИП\t322213000008338\n" +
    "ИНН\t110381171805\n" +
    "Адрес:\n" +
    "429218, Чувашская Республика - Чувашия, Вурнарский район, деревня Сявалкасы ул. Илеберская д.21\n" +
    "p/c\t40802810015960002437\n" +
    "Банк\tВТБ  (ПАО)\n" +
    "БИK\t044525411\n" +
    "Основной вид деятельности\t47.91 Торговля розничная по почте или по информационно-коммуникационной сети Интернет\n"
const Contacts: FC = () => {

    return (
        <>
            <div className={"contentHead"}>
                <h2>Контакты</h2>
                <img src={"./img/logo_main.png"} alt={"img/logo_main.png"}/>

            </div>
            <div className={"routerPageContent"}>
                <div className={cl.wrapper}>

                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span><strong>Наименование</strong></span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span><strong>ИП Тереков Сергей Иванович</strong></span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>ОГРН/ИНН</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>322213000008338 / 110381171805</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>Телефон</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <a href="tel:+7(927)-666-63-08">+7(927)-666-63-08</a>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>Режим работы:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>Пн.-Пт. 9:00 - 23:00</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>Почтовый адрес:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>429218, Чувашская Республика - Чувашия, Вурнарский район, деревня Сявалкасы ул. Илеберская д.21</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>e-mail:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <a href="mailto:west1992@mail.ru">west1992@mail.ru</a>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <span className={cl.wrapperItemsContent}><strong>БАНКОВСКИЕ РЕКВИЗИТЫ</strong></span>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>БАНК:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>ВТБ (ПАО)</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>Расчётный счёт:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>40802810015960002437</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>Корр. счёт:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>30101810145250000411</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>БИК:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>044525411</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItems}>
                        <div className={cl.wrapperItemsTitle}>
                            <span>Валюта счёта:</span>
                        </div>
                        <div className={cl.wrapperItemsContent}>
                            <span>РОССИЙСКИЙ РУБЛЬ</span>
                        </div>
                    </div>
                    <div className={cl.wrapperItemsFinaly}>

                    </div>
                </div>



            </div>


        </>
    );
};

export default Contacts;