import React, {FC, useEffect} from 'react';
import {AllTariffs, Tariff} from "../../appStructs/tariff";
import TariffView from "../../components/TariffView/TariffView";



const Tariffs: FC = () => {

    useEffect(()=>{

        return ()=>{}
    },)
    return (
        <div>
            <div className={"contentHead"}>
                <h2>Тарифы</h2>
                <img  src={"img/logo_main.png"} alt={"img/logo_main.png"}/>

            </div>
            <div className={"routerPageContent"}>
                {
                    AllTariffs.map((t:Tariff,ind:number)=><TariffView tariff={t} key={"tariff"+ind}/>)
                }
            </div>
        </div>
    );
};

export default Tariffs
