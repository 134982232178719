import React from "react";
import {Domen} from "../fetches/Requests";

// @ts-ignore
import Main from "../pages/main/Main";
import Payment from "../pages/Payment/Payment";
import Tariffs from "../pages/Tariffs/Tariffs";
import Contacts from "../pages/Contacts/Contacts";



export enum RouterPath {
    MAIN = "/",
    PAYMENT="/payment",
    TARIFFS="/tariffs",
    CONTACTS="/contacts"

}

export type RouteNode = {
    isHide: boolean
    path: string,
    name: string,
    element: JSX.Element
    img?: string
}

export enum RouterTexts {
    MAIN = "Главная",
}

export type TRouterMap = {
    MAIN: RouteNode,
    Payment:RouteNode,
    Tariffs:RouteNode,
    Contacts:RouteNode


}

export const RouterMap: TRouterMap = {
    MAIN:  {
        isHide: false,
        path: RouterPath.MAIN,
        name: "ГЛАВНАЯ",
        element: <Main/>,
        img: "",
    },
    Payment: {
        isHide: false,
        path: RouterPath.PAYMENT,
        name: "ОПЛАТА",
        element: <Payment/>,
        img: "",
    },
    Tariffs: {
        isHide: false,
        path: RouterPath.TARIFFS,
        name: "Тарифы",
        element: <Tariffs/>,
        img: "",
    },
    Contacts:{
            isHide: false,
            path: RouterPath.CONTACTS,
            name: "Контакты",
            element: <Contacts/>,
            img: "",
    }


}

