import React, {FC} from 'react';
import {Tariff} from "../../appStructs/tariff";
// @ts-ignore
import cl from"./TariffView.module.css"
interface TariffViewProps {
    tariff:Tariff
}

const TariffView:FC<TariffViewProps> = (props:TariffViewProps) => {
    return (
        <div className={cl.wrapper}>
            <h3>{props.tariff.Name}</h3>
            <h2 className={cl.price}> {props.tariff.Price} &#8381;(руб.)</h2>
            <h4>Доступ на {props.tariff.UsageDays} дней</h4>
            <p className={cl.description}>{props.tariff.Description}</p>
        </div>
    );
};

export default TariffView;